import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function ParallaxNew() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const titleY = useTransform(scrollYProgress, [0, 0.85], ["0%", "-40%"]);
  const descriptionY = useTransform(scrollYProgress, [0, 0.9], ["0%", "-40%"]);
  const awardY = useTransform(scrollYProgress, [0, 0.8], ["0%", "-30%"]);

  return (
    <div
      ref={ref}
      className="w-full h-screen overflow-hidden relative grid place-items-center "
    >
      <motion.div
        className="text-5xl md:text-7xl leading-tight text-gray-300 text-block drop-shadow-lg relative z-40"
        style={{ fontFamily: "Bebas Neue", y: titleY }}
      >
        <h1 data-aos="fade-left" data-aos-delay="300">
          National
          <br /> Educator
          <br /> Awards
          <br /> 2024
        </h1>
      </motion.div>

      <div className="absolute inset-0 z-30 bg-slate-900/40"></div>

      <motion.div
        style={{ y: awardY }}
        id="logoland"
        className="inset-0"
        transition={{
          delay: 0,
        }}
      >
        <img
          data-aos="fade-right"
          src="/assets/img/award.png"
          data-aos-delay="300"
        />
      </motion.div>

      <motion.div
        className="description-block inset-0 absolute z-40"
        style={{ y: descriptionY }}
      >
        <p
          className="text-sm md:text-xl leading-8 text-gray-200 mb-10 text-center md:text-left max-w-xl drop-shadow-lg"
          data-aos="fade-down"
          data-aos-delay="350"
        >
          Welcome to the National Educator Awards (NEA), where excellence in
          education is celebrated and recognized.
          <br />
          <br />
          NEA is a prestigious initiative dedicated to honoring outstanding
          educators across Sri Lanka who have made remarkable contributions to
          their respective academic disciplines.
          <br />
          <br />
          Our mission is to shine a spotlight on the extraordinary individuals
          who shape the future of education in our nation.
          <br />
          <br />
          <b>You will be evaluated based on:</b>
          <br />
          <br />
          <ul className="list-disc ml-6 text-left">
            <li>Academic Contribution</li>
            <li>Research, Scholarship, and Creative Work</li>
            <li>
              Contributions to Institute / National / International Development
            </li>
          </ul>
        </p>
        <div className="flex gap-10">
          <HashLink
            to="/#aboutus"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            className="mx-auto lg:mx-0 rounded-sm bg-orange-500 px-6 lg:px-10 py-3 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            FIND OUT MORE
          </HashLink>
          {/* <Link
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            to="/apply"
            className="mx-auto lg:mx-0 rounded-sm bg-orange-500 px-6 lg:px-10 py-3 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            APPLY NOW
          </Link> */}
          <Link
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            to="/judgestable"
            className="fancy w-[155px] lg:w-[200px] rounded-sm bg-orange-500 px-6 lg:px-10 py-3 shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span className="w-fit px-2 lg:px-8 py-3 mx-auto lg:mx-0 text-xs lg:text-sm font-semibold text-white">
              JUDGING SCHEDULE
            </span>
          </Link>
        </div>
      </motion.div>

      <motion.div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(/assets/img/sky.jpg)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundY,
        }}
      />
      <div
        className="absolute inset-0 z-20"
        style={{
          backgroundImage: `url(/assets/img/mountains2.png)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
}
