import React from "react";

export const JudgesTable = () => {
  const awards2023 = [
    {
      province: "Sabaragamuwa",
      district: "Kegalle",
      location: "St. Mary's National College",
      date: "08-Oct",
    },
    {
      province: "Western Province",
      district: "Gampaha",
      location: "Bandaranayaka College, Gampaha",
      date: "01-Nov",
    },
    {
      province: "Western Province",
      district: "Kaluthara",
      location: "Tissa College Kalutara/ Colombo",
      date: "12-Nov",
    },
    {
      province: "Southern Province",
      district: "Matara",
      location: "St. Mary's Convent - Matara",
      date: "22-Oct",
    },
    {
      province: "Northern Province",
      district: "Jaffna",
      location: "Northern UNI - SLIIT",
      date: "30-Oct",
    },
    {
      province: "Central Province",
      district: "Kandy",
      location: "Kandy UNI - SLIIT",
      date: "24-Oct",
    },
    {
      province: "North Western Province",
      district: "Kurunegala",
      location: "Maliyadeva Boys College",
      date: "09-Oct",
    },
    {
      province: "North Central Province",
      district: "Polonnaruwa",
      location: "Polonnaruwa Royal College",
      date: "06-Nov",
    },
    {
      province: "Eastern Province",
      district: "Trincomalee",
      location: "Sinhala Central College Trincomalee",
      date: "05-Nov",
    },
    {
      province: "Uva Province",
      district: "Badulla",
      location: "Dharmadhutha College Badulla",
      date: "15-Oct",
    },
  ];

  return (
    <>
      <div className="w-full h-full min-h-screen py-10 lg:py-6 relative">
        <h1
          className="text-3xl uppercase font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
          style={{ fontFamily: "Poppins" }}
          data-aos="fade-down"
          data-aos-delay="100"
        >
          JUDGING SCHEDULE
        </h1>
        <div className="flex max-w-screen-2xl mt-10 flex-col mx-auto">
          <div className="text-white text-center text-lg font-semibold py-4">
            National Educator Awards 2024
          </div>
          <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse text-left bg-gray-800 text-white">
              <thead className="bg-gray-900">
                <tr className="bg-gray-700 text-gray-300">
                  <th className="py-2 px-4"></th>
                  <th className="py-2 px-4">Province</th>
                  <th className="py-2 px-4">Judging District</th>
                  <th className="py-2 px-4">Judging Location</th>
                  <th className="py-2 px-4">Date</th>
                </tr>
              </thead>
              <tbody>
                {awards2023.map((award, index) => (
                  <tr
                    key={award.district}
                    className="border-b border-gray-700 hover:bg-gray-600 transition duration-300"
                  >
                    <td className="py-3 px-4">{index + 1}</td>
                    <td className="py-3 px-4">{award.province}</td>
                    <td className="py-3 px-4">{award.district}</td>
                    <td className="py-3 px-4">{award.location}</td>
                    <td className="py-3 px-4 min-w-[90px]">{award.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
